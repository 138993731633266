// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Button from 'components/base/Button';
// import { Col, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import boltIlls from 'assets/img/icons/illustrations/bolt.png';
// import pie from 'assets/img/icons/illustrations/pie.png';
// import { defaultFeatures } from 'data/landing/default-landing-data';
// import FeatureSection from './FeatureSection';
// import workflow from 'assets/img/protoniumimages/workflow.png';
// import contributionmargin from 'assets/img/protoniumimages/contributionmargin.png';
// import darkstoresdensity from 'assets/img/protoniumimages/darkstoresdensity.png';
// import FinancialsYCS24 from 'assets/img/protoniumimages/FinancialsYCS24.png';
import grantedpatent from 'assets/img/protoniumimages/grantedpatent.png';
import profilepic from 'assets/img/protoniumimages/profilepic.png';
// import { Col, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Features = () => {
  return (
    <section className="bg-soft pt-15 pb-18" id="features">
      <div className="container-small px-lg-7 px-xxl-3">
        <div className="position-relative z-index-2 text-center">
          {/* <h1>System Workflow</h1>
          <p>
            <br />
            Nav (Drone for last mile delivery – Station to Station) <br />
            Neo (Rover for doorstep pickup and delivery or Indoor pickup/drop
            Station) <br />
            Station - Dock for Nav and Neo, which implies a closed facility
            recharging and parking at off hours
            <br />
            some stations may also have battery swapping and others may have
            package management
          </p>
          <img src={workflow} className="w-100" />
          <p>
            <br />
            Here, pick up station at site A features Dock for both Nav and Neo,
            whereas station at site B only has dock for Neo. <br />
            Also, station at site A has a Battery Swap facility for Nav. The
            station at site B has package management. <br />
            As per requirements, station can have docking facility, battery swap
            and package management functionality. <br />
          </p> */}
          {/* <br />
          <br /> */}
          <div>
            <h1 className="fw-bolder mb-2">Protonium YC W25</h1>
            <h2 className="fw-semi-bold lh-sm">Demo video</h2>
            <br />
            <br />
          </div>
          <iframe
            width="320"
            height="400"
            className="w-75"
            src="https://www.youtube.com/embed/EUFV0TsiJM8?si=B3b8Z4edcp7MjQmu"
            title="YouTube video player"
            // frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
          {/* <iframe
            width="320"
            height="400"
            className="w-75"
            // src="https://www.youtube.com/embed/LpsTLQeEt0s?si=qPR77pjQBlPLJJ3r"
            src="https://youtu.be/EUFV0TsiJM8"
            title="YouTube video player"
            // frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe> */}
          {/* <br />
          <br /> */}
          {/* <h1 className="my-5">
            <br />
            Technical Features
          </h1> */}
          {/* <Row className="mb-12">
            <Col lg={6} className="text-center text-lg-start pe-xxl-3">
              <h4 className="text-primary fw-bolder mb-4">Features</h4>
              <h2 className="mb-3 text-black lh-base">
                A fully integrated suite
                <br />
                of payments products
              </h2>
              <p className="mb-5">
                With the power of Phoenix, you can now focus only on
                functionaries for your digital products, while leaving the UI
                design on us!With the power of Phoenix, you can now focus only
                on functionaries for your digital products, while leaving the UI
                design on us!
              </p>
              <Button
                as={Link}
                to="#!"
                size="lg"
                variant="outline-primary"
                className="rounded-pill me-2"
                endIcon={
                  <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                }
              >
                Find out more
              </Button>
            </Col>
            <Col sm={6} lg={3} className="mt-7 text-center text-lg-start">
              <div className="h-100 d-flex flex-column justify-content-between">
                <div className="border-start-lg border-dashed ps-4">
                  <img
                    className="mb-4"
                    src={boltIlls}
                    width={48}
                    height={48}
                    alt=""
                  />
                  <div>
                    <h5 className="fw-bolder mb-2">Lightning Speed</h5>
                    <p className="fw-semi-bold lh-sm">
                      Present everything you need in one place within minutes!
                      Grow with Phoenix!
                    </p>
                  </div>
                  <Link to="#!" className="me-2 p-0 fs-9 fw-bold">
                    Check Demo
                    <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                  </Link>
                </div>
              </div>
            </Col>
            <Col sm={6} lg={3} className="mt-7 text-center text-lg-start">
              <div className="h-100 d-flex flex-column">
                <div className="border-start-lg border-dashed ps-4">
                  <img
                    className="mb-4"
                    src={pie}
                    width={48}
                    height={48}
                    alt=""
                  />
                  <div>
                    <h5 className="fw-bolder mb-2">All-in-one solution</h5>
                    <p className="fw-semi-bold lh-sm">
                      Show your production and growth graph in one place with
                      Phoenix!
                    </p>
                  </div>
                  <Link to="#!" className="me-2 p-0 fs-9 fw-bold">
                    Check Demo
                    <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row> */}
          {/* {defaultFeatures.map((feature, index) => (
            <>
              <FeatureSection
                key={feature.label}
                feature={feature}
                orderReverse={index % 2 === 0}
              />
              <hr className="mx-auto my-6" style={{ maxWidth: 400 }} />
            </>
          ))} */}
          {/* <h1 className="my-5">
            <br />
            Unit Economics Numbers
          </h1>
          <p>
            <br />
            BlinkIt (QComm) having 46% Market share by GMV in India <br />
            and Zomato (Food Delivery) having 50+% Market share by GMV in India
            <br />
          </p>
          <img src={contributionmargin} className="w-100" />
          <p>
            <br />
            For QComm, Delivery Cost & Dark Store expenses for the biggest cost
            component,
            <br />
            And for Food Delivery, Delivery Cost is the largest cost component
            <br />
            <Link
              to={
                'https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://www.jmfl.com/Common/getFile/3278&ved=2ahUKEwj_wd2foNyGAxXJ9DgGHVUABG8QFnoECBIQAQ&usg=AOvVaw0ofkDVYVd42h3HLtHA4jOu'
              }
            >
              JM Financial Report (2024) - Deep-Dive: Quick Commerce
              <br />
            </Link>
            <br /> <br />
            <Link
              to={
                'https://www.financialexpress.com/business/industry-blinkit-to-focus-on-top-8-cities-for-dark-stores-3486595/'
              }
            >
              Blinkit to focus on top 8 cities for dark stores,
              <br />
              has 526 dark stores across India and plans to double the number to
              1,000 in the next 12 months <br />
              - Financial Express <br /> <br /> <br /> <br />
            </Link>
          </p> */}
          {/* <h4 className="my-5">
            <br />
            Dark Store Density
          </h4>
          <img src={darkstoresdensity} className="w-70" />
          <p>
            <br />
            An estimated representation of Dark Stores Density of 3 major
            players in Q Comm <br /> (Zomato's BlinkIt, Swiggy's Instamart and
            Zepto) in a tier 1 city in India (30+ each per city)
            <br /> <br />
            VS An estimated representation of Protonium's model of Automated
            Dark Stores (6-8 per city) <br /> <br />
            Using Automation and Drone Delivery, 6-8 stores can offer 10 min
            delivery to the whole city
            <br /> <br />
          </p>
          <h4 className="my-5">
            <br />
            Protonium Unit Economics
          </h4>
          <img src={FinancialsYCS24} className="w-100" />
          <br /> <br />
          <h1 className="my-5">
            <br /> <br /> <br /> <br />
            FAQs - Problems in automation <br /> Protonium's designs solve all
            these problems <br />
          </h1> */}
          {/* <h4 className="my-5">
            <br />
            I talked to so many people and while ther're excited about this,
            they had all these concerns about flying drones and moving robots !
            <br />
            With many iterations over designs and implementation plan, <br />
            now Protonium's designs are robust and solve all these concerns{' '}
            <br />
          </h4>
          <p>
            <br />
            Can a Drone crash with an Airplane or Helicopter?
            <br />
            What happens if some emergency is declared in airspace over a city?
            <br />
            Someone can snatch your Drone and steal/damage it.
            <br />
            Someone can snatch your Rover and steal/damage it.
            <br />
            Drone will get stuck in trees/other structure.
            <br />
            Rover will get stuck/damage/get damaged in some structure on ground.
            <br />
            Drone will crash if birds attack it.
            <br />
            Drones will crash with each other.
            <br />
            Drone will fall on someone/something and hurt/damage seriously.
            <br />
            Drone will get hacked.
            <br />
            Drone might carry explosive/harmful stuff in payload.
            <br />
            Drone can fall/crash due to some failure onboard.
            <br />
            Drone camera causes breach of privacy.
            <br />
            Drone is very noisy and causes disturbance.
            <br />
            Drone System will not work in bad weather.
            <br />
            Lightning Strike on Drone?
            <br />
            Drone will heat up/cool down while operating in Sun or in Cold?
            <br />
            Drone camera won’t work at night/in fog/rain (low visibility)
            causing a crash.
            <br />
            Drone GPS can have glitch causing a crash.
            <br />
            Drone sensors can give faulty data causing a crash.
            <br />
            Drone communication with Station / Server can get disconnected
            causing a crash.
            <br />
            Drone can hit some lose hanging wire or branch.
            <br />
            Drone can hit a temporary structure like a moving crane.
            <br />
            Drone will free fall upon failure and cause fatal injuries and
            serious damages.
            <br />
            Drone with Parachute will still fall on a moving motorbike and cause
            a fatal accident.
            <br />
            Is a human controller present on standby to handle unexpected
            situation/falling of drone?
            <br />
            Drone can fall from low altitude when there’s no time to deploy a
            parachute to reduce speed.
            <br />
            Some small animal or bird can get inside Station.
            <br />
            Someone can cause damage to Station.
            <br />
            Station power supply can be interrupted.
            <br />
            Lightning Strike on Station?
            <br />
            Hailstorm and Cyclone on Station?
            <br />
            Rover can miss to detect some obstruction/hole and hit/fall/topple
            and get damaged, and require someone to put it back upright?
            <br />
            Rover might walk over some pile of shit and get smelly and keep
            spreading it across.
            <br />
            A child might jump in front of/attack the rover.
            <br />
            Rover and Drone will have a large downtime for recharging their
            batteries?
            <br />
            Is a human controller present on standby to handle unexpected
            situation of rover and station?
            <br />
            Where will all the drones go at off hours or when on Standby?
            <br />
            What happens if a Drone has some failure and has to go under
            maintenance?
            <br />
            What happens if a rover has some failure and has to go under
            maintenance?
            <br />
            <br /> <br />
          </p>
          <br />
          <h1 className="my-5">
            <br /> <br /> <br /> <br />
            Some of my earlier projects!
          </h1> */}
          <h1 className="my-5">
            <br />
            Autonomous Battery Swapping and Recharging System <br />
            Indian Patent Granted - 315309
          </h1>
          <img src={grantedpatent} className="h-100" />
          <br /> <br />
          <h1 className="my-5">
            <br />
            Anamay Prateek <br />
            Founder & CEO
          </h1>
          <h3 className="my-5">
            <br />
            anamay.prateek@protonium.in <br />
            +91 75501 70250
          </h3>
          <img src={profilepic} className="h-100" />
          <br /> <br />
          {/* <h4 className="my-5">
            <br />
            YouTube videos of other projects - <br />
          </h4> */}
        </div>
      </div>
      {/* <div className="container-small px-lg-7 px-xxl-3">
        <div className="position-relative z-index-2 text-center">
          <Row className="mb-12">
            <Col lg={4} className="mt-7 text-center text-lg-start">
              <div className="h-100 d-flex flex-column justify-content-between">
                <div className="border-start-lg border-dashed ps-4">
                  <iframe
                    width="320"
                    height="240"
                    src="https://www.youtube.com/embed/lJhyasCdoXw?si=mCszC_cwAABYnNe5"
                    title="YouTube video player"
                    // frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                  <br />
                  <br />
                  <div>
                    <h5 className="fw-bolder mb-2">Battery Swap - CAD</h5>
                    <p className="fw-semi-bold lh-sm">
                      Autonomous Battery Swapping and Recharging - CAD
                      Simulation
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="mt-7 text-center text-lg-start">
              <div className="h-100 d-flex flex-column justify-content-between">
                <div className="border-start-lg border-dashed ps-4">
                  <iframe
                    width="320"
                    height="240"
                    src="https://www.youtube.com/embed/ZCHrUtkRJzc?si=cXtTsRB1sjZPyJYo"
                    title="YouTube video player"
                    // frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                  <br />
                  <br />
                  <div>
                    <h5 className="fw-bolder mb-2">Battery Swap - Prototype</h5>
                    <p className="fw-semi-bold lh-sm">
                      Autonomous Battery Swapping and Recharging - Robotic Arm
                      Prototype
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="mt-7 text-center text-lg-start">
              <div className="h-100 d-flex flex-column justify-content-between">
                <div className="border-start-lg border-dashed ps-4">
                  <iframe
                    width="320"
                    height="240"
                    src="https://www.youtube.com/embed/k5VOqexirxw?si=EFnXyhYB0qPtzxAE"
                    title="YouTube video player"
                    // frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                  <br />
                  <br />
                  <div>
                    <h5 className="fw-bolder mb-2">Gesture - Drone control</h5>
                    <p className="fw-semi-bold lh-sm">
                      Drone control using Hand gestures
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-12">
            <Col lg={4} className="mt-7 text-center text-lg-start">
              <div className="h-100 d-flex flex-column justify-content-between">
                <div className="border-start-lg border-dashed ps-4">
                  <iframe
                    width="320"
                    height="240"
                    src="https://www.youtube.com/embed/Xhb9rXbZzLc?si=UIJr1ztGvp-aIlLl"
                    title="YouTube video player"
                    // frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                  <br />
                  <br />
                  <div>
                    <h5 className="fw-bolder mb-2">Drone Payload mechanism</h5>
                    <p className="fw-semi-bold lh-sm">
                      Drone that can attach and carry a package
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="mt-7 text-center text-lg-start">
              <div className="h-100 d-flex flex-column justify-content-between">
                <div className="border-start-lg border-dashed ps-4">
                  <iframe
                    width="320"
                    height="240"
                    src="https://www.youtube.com/embed/gQVHluzjmOk?si=PRBogrPKVC8LyAnn"
                    title="YouTube video player"
                    // frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                  <br />
                  <br />
                  <div>
                    <h5 className="fw-bolder mb-2">Autonomous Flight Test</h5>
                    <p className="fw-semi-bold lh-sm">
                      Drone long range flight test at night
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="mt-7 text-center text-lg-start">
              <div className="h-100 d-flex flex-column justify-content-between">
                <div className="border-start-lg border-dashed ps-4">
                  <iframe
                    width="320"
                    height="240"
                    src="https://www.youtube.com/embed/dN2_xJYvCnM?si=6wIfnuiWnIwf3Qax"
                    title="YouTube video player"
                    // frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                  <br />
                  <br />
                  <div>
                    <h5 className="fw-bolder mb-2">Tilt-rotor UAV design</h5>
                    <p className="fw-semi-bold lh-sm">
                      Hybrid Drone design that can vectorize thrust
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <br />
          <br />
          <div>
            <h1 className="fw-bolder mb-2">
              Protonium timeline (Dec 23 - May 24)
            </h1>
            <p className="fw-semi-bold lh-sm">Dev & Testing</p>
            <br />
            <br />
          </div>
          <iframe
            width="320"
            height="600"
            className="w-75"
            src="https://www.youtube.com/embed/wGvaPvouD84?si=gLJGejdcy0DogtgS"
            title="YouTube video player"
            // frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
          <br />
          <br />
          <br />
          <br />
          <div>
            <h5 className="fw-bolder mb-2">Bloopers</h5>
            <p className="fw-semi-bold lh-sm">
              crashes that we try to minimize, but are part of the journey
            </p>
            <br />
            <br />
          </div>
          <iframe
            width="320"
            height="400"
            className="w-75"
            src="https://www.youtube.com/embed/v4tHoFNWnNQ?si=99-BjhG89k1eIdoV"
            title="YouTube video player"
            // frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
          <br />
          <br />
          <br />
          <br />
          <h4>Thank you :)</h4>
        </div>
      </div> */}
    </section>
  );
};

export default Features;
